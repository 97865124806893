import {Component} from "react";
import {Trans} from "@lingui/macro";

class Footer extends Component {

    constructor(props) {
        super(props);
        this.email = props.email;
        this.links = props.links;
        this.mailto = props.mailto;
    }

    render() {
        return (
            <footer className="footer">
                <div className="footer__row">
                    <div className="footer__cell footer__cell--full-width">
                        <a href={this.mailto} className="footer__link footer__link--hover-anim-bg">
                            <span className="footer__link-text"><Trans>GET_IN_TOUCH</Trans></span>
                            <span className="footer__link-arrow footer__link-arrow--anim-position"/>
                        </a>
                    </div>
                </div>
                <div className="footer__row footer__row--bordered">
                    <div className="footer__cell">
                        <a href={this.links.behance} target="_blank" rel="noreferrer" className="footer__link footer__link--hover-anim-text">
                            <span className="footer__link-text">Behance</span>
                        </a>
                    </div>
                    <div className="footer__cell">
                        <a href={this.links.dribbble} target="_blank" rel="noreferrer" className="footer__link footer__link--hover-anim-text">
                            <span className="footer__link-text">Dribbble</span>
                        </a>
                    </div>
                    <div className="footer__cell">
                        <a href={this.links.instagram} target="_blank" rel="noreferrer" className="footer__link footer__link--hover-anim-text">
                            <span className="footer__link-text">Instagram</span>
                        </a>
                    </div>
                    <div className="footer__cell">
                        <a href={this.links.linkedin} target="_blank" rel="noreferrer" className="footer__link footer__link--hover-anim-text">
                            <span className="footer__link-text">LinkedIn</span>
                        </a>
                    </div>
                </div>
            </footer>
        );
    }
}

export default Footer;
