import React from "react";
import {ReactComponent as RollingSmile} from "./../Assets/svg/rolling-smile.svg";
import anime from "animejs";

export default class Preloader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {show: true, show2: true};
    }

    componentDidMount() {
        this.timeoutId = setTimeout(function () {
            this.setState({show: false});
        }.bind(this), 6000);
        this.timeoutId2 = setTimeout(function () {
            this.setState({show2: false});
        }.bind(this), 7000);

        // Preset needed top offset
        let offsetTop = document.querySelector(".looking-smiley").getBoundingClientRect().top + window.scrollY
        document.querySelector(".rolling-smiley").style.top = offsetTop + 'px'

        let timeline = anime.timeline({loop: false});

        timeline.add({
            targets: ".rolling-smiley__icon",
            rotate: 360 * 11 + "deg",
            duration: 6500,
            easing: () => t => t < .5 ? 13 * t * t * t * t * t : 1 + 9 * (--t) * t * t * t * t,
        });

        timeline.add({
            targets: ".rolling-smiley",
            duration: 6500,
            easing: "cubicBezier(.8, 0, .2, 1)",
            translateX: ["-8vw", "105vw"],
        }, 0);

        timeline.add({
            targets: ".rolling-smiley__line",
            easing: "linear",
            width: [
                {value: "60vw", duration: 3000},
                {value: "0", duration: 3000},
            ],
        }, 0);
    }

    componentWillUnmount() {
        if (this.timeoutId) {
            clearTimeout(this.timeoutId);
        }

        if (this.timeoutId2) {
            clearTimeout(this.timeoutId2);
        }
    }

    render() {
        return (
            <div
                className={"preloader " + (this.state.show ? "preloader--show" : "preloader--hide") + (this.state.show2 ? "" : " preloader--none")}>
                <div className="preloader__wrapper">
                    <div className="rolling-smiley">
                        <RollingSmile/>
                        <div className="rolling-smiley__line"/>
                    </div>
                </div>
            </div>
        );
    }
}
