import React from "react";
import {ReactComponent as LookingSmile} from "./../Assets/svg/looking-smiley.svg";
import anime from "animejs";

export default class LookingSmiley extends React.Component {
    constructor(props) {
        super(props);
        this.state = {show: true, show2: true};
    }

    onMouseMove = (e) => {
        let el = document.getElementById("looking-smiley"),
            rect = el.getBoundingClientRect(),
            degBound = 85,
            x = e.clientX,
            y = e.clientY,
            x2 = Math.round(rect.left + ((rect.right - rect.left) / 2)),
            y2 = Math.round(rect.top + ((rect.bottom - rect.top) / 2))
        ;

        let xdeg = Math.round((x - x2) * 0.05);
        xdeg = Math.abs(xdeg) > degBound ? degBound * Math.sign(xdeg) : xdeg;

        let ydeg = Math.round((y - y2) * (-0.05));
        ydeg = Math.abs(ydeg) > degBound ? degBound * Math.sign(ydeg) : ydeg;

        el.style.transform = "rotateY(" + xdeg + "deg) rotateX(" + ydeg + "deg)";
    };

    componentDidMount() {
        let tl = anime.timeline({loop: false});
        let that = this;
        tl.add({
            targets: ".looking-smiley",
            rotate: 360 * 2 + "deg",
            duration: 2100,
            easing: "easeInQuad",
        }, 6500);

        let styles = getComputedStyle(document.querySelector(".looking-smiley"))

        tl.add({
            targets: ".looking-smiley",
            duration: 2000,
            easing: "easeInQuad",
            left: ["-500px", styles.left],
            complete: function(anim) {
                document.body.addEventListener("mousemove", that.onMouseMove);
            }
        }, 6500);
    };

    componentWillUnmount() {
        document.body.removeEventListener("mousemove", this.onMouseMove);
    };

    render() {
        return (
            <div className="looking-smiley">
                <LookingSmile/>
            </div>
        );
    }
}
