import React from "react";
import App from "./App/App";
import {I18nProvider} from "@lingui/react";
import {i18n} from "@lingui/core";
import {createRoot} from "react-dom/client";

const container = document.getElementById("app");
const root = createRoot(container);

const Translation = () => {
    return (
        <React.StrictMode>
            <I18nProvider i18n={i18n}>
                <App />
            </I18nProvider>
        </React.StrictMode>
    );
};

root.render(<Translation />);
