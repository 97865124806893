import {Component, Suspense} from "react";
import LookingSmiley from "./LookingSmiley";
import anime from "animejs";
import Splitting from "splitting";
import {Trans, t} from "@lingui/macro";

import "splitting/dist/splitting.css";

class Main extends Component {

    componentDidMount() {
        Splitting({target: document.querySelectorAll(".tagline__row"), by: "chars"});
        Splitting({target: document.querySelector(".lead"), by: "chars"});

        setTimeout(() => {
            anime.timeline({loop: false}).add({
                targets: ".tagline__row .char",
                translateY: [100, 0],
                translateZ: 0,
                opacity: [0, 1],
                easing: "easeOutExpo",
                duration: 1400,
                delay: (el, i) => 300 + 30 * i,
            }).add({
                targets: ".lead .char",
                scale: [0, 1],
                duration: 1500,
                elasticity: 600,
                delay: (el, i) => 45 * (i + 1),
            })
        }, 6000)
    }

    render() {
        return (
            <main className="main">
                <Suspense fallback="loading">
                    <div className="sections">
                        <div className="sections__item sections__item--primary">
                            <div className="tagline">
                                <div className="tagline__row tagline__row--first"><Trans>SLOGAN_PART_ONE</Trans></div>
                                <div className="tagline__row tagline__row--second"><Trans>SLOGAN_PART_TWO</Trans></div>
                                <div className="tagline__row tagline__row--third"><Trans>SLOGAN_PART_THREE</Trans></div>
                                <div className="tagline__row tagline__row--fourth"><Trans>SLOGAN_PART_FOUR</Trans></div>
                            </div>
                            <LookingSmiley/>
                        </div>
                        <div className="sections__item sections__item--secondary">
                            <div className="lead" dangerouslySetInnerHTML={{__html: t`SUB_SLOGAN`}}/>
                        </div>
                    </div>
                </Suspense>
            </main>
        );
    }
}

export default Main;
