import {Component} from "react";
import "./Assets/styles/app.sass";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import Main from "./Components/Main";
import Preloader from "./Components/Preloader";

export default class App extends Component {
    data = {
        email: "team@xplines.digital",
        mailto: "mailto:team@xplines.digital?subject=Hello%20Xplines&body=My%20name%20is%20...",
        links: {
            behance: "https://www.behance.net/xplinesdigital",
            dribbble: "https://dribbble.com/XplinesDigital",
            instagram: "https://www.instagram.com/xplinesdigital",
            linkedin: "https://www.linkedin.com/company/xplines-digital",
        },
    };

    render() {
        return (
            <>
                <Preloader/>
                <Header email={this.data.email} mailto={this.data.mailto}/>
                <Main/>
                <Footer email={this.data.email} links={this.data.links} mailto={this.data.mailto}/>
            </>
        );
    }
}
