import {useLingui} from "@lingui/react";
import {i18n} from '@lingui/core';
import {detect, fromStorage} from '@lingui/detect-locale';

export const localeStorageKey = "userLocale";
export const defaultLocale = "uk-UA";
export const locales = {
    "uk-UA": "Українська",
    "en-US": "English",
}

export function getLocale() {
    const detectedLocale = detect(fromStorage(localeStorageKey), () => defaultLocale);

    return detectedLocale in locales ? detectedLocale : defaultLocale;
}

export async function activateLocale(locale) {
    const {messages} = await import(`./../../Locale/${locale}`);
    i18n.load(locale, messages);
    i18n.activate(locale);
    document.documentElement.lang = locale;
    window.localStorage.setItem(localeStorageKey, locale);
}

activateLocale(getLocale());

export function LangSwitcher(props) {
    const {i18n} = useLingui();
    const calcCssClass = (locale) => {
        return "lang-switcher__entry" + (i18n.locale === locale ? " lang-switcher__entry--switched" : "");
    }

    return <ul className={"lang-switcher" + (props.classes ? " " + props.classes : "")} data-current={i18n.locale}>
        <li onClick={() => activateLocale("uk-UA")} className={calcCssClass("uk-UA")}>UA</li>
        <li className="lang-switcher__divider"/>
        <li onClick={() => activateLocale("en-US")} className={calcCssClass("en-US")}>EN</li>
    </ul>;
}

export default LangSwitcher
