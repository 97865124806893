import {Component} from "react";

export default class LocalTime extends Component {
    state = {time: this.getCurrentTime()};

    componentDidMount() {
        setInterval(() => {
            this.setState({time: this.getCurrentTime()})
        }, 1000);
    }

    getCurrentTime() {
        return (new Intl.DateTimeFormat("en", {
            timeZone: "Europe/Kiev",
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
        })).format(new Date());
    }

    render() {
        return (
            <>
                <div className="local-time">{this.props.timeTitle} {this.state.time}</div>
            </>
        );
    }
}
