import {Component} from "react";
import {ReactComponent as Logo} from "./../Assets/svg/logo.svg";
import LocalTime from "./LocalTime";
import LangSwitcher from "./LangSwitcher";
import CompanyInfo from "./CompanyInfo";

class Header extends Component {

    constructor(props) {
        super(props);
        this.email = props.email;
        this.mailto = props.mailto;
    }

    state = {menuSwitched: false};

    toggleSwitched = () => {
        this.setState({menuSwitched: !this.state.menuSwitched});
    };

    calculateSwitchedClass = () => {
        return "menu-switcher" + (this.state.menuSwitched ? " menu-switcher--switched" : "");
    };

    render() {
        return (
            <header className="header">
                <div className={"header__curtain" + (this.state.menuSwitched ? " header__curtain--opened" : "")}>
                    <div className="header__curtain-wrapper">
                        <LangSwitcher classes="lang-switcher--under-curtain"/>
                        <CompanyInfo classes="company-info--under-curtain" mailto={this.mailto} email={this.email}/>
                    </div>
                </div>
                <div className="header__wrapper">
                    <div className="logo">
                        <Logo className="logo__svg"/>
                    </div>
                    <LocalTime timeTitle="Local Time"/>
                    <CompanyInfo mailto={this.mailto} email={this.email}/>
                    <LangSwitcher classes="lang-switcher--pushed-right"/>
                    <div className={"menu-switcher" + (this.state.menuSwitched ? " menu-switcher--switched" : "")} onClick={this.toggleSwitched}>
                        <div className="menu-switcher__icon"/>
                    </div>
                </div>
            </header>
        );
    }
}

export default Header;
