import {Component, Suspense} from "react";
import {Trans} from "@lingui/macro";

class CompanyInfo extends Component {

    constructor(props) {
        super(props);
        this.classes = props.classes;
        this.email = props.email;
        this.mailto = props.mailto;
    }

    render() {
        return (
            <div className={"company-info" + (this.classes ? " " + this.classes : "")}>
                <span className="company-info__location">
                    <Suspense fallback="loading">
                        <Trans>LOCATION</Trans>
                    </Suspense>
                </span>
                <a href={this.mailto} className="company-info__email">{this.email}</a>
            </div>
        );
    }
}

export default CompanyInfo;
